.popup_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  display: flex;
  justify-content: center; /* center horizontally */
  align-items: center; /* center vertically */
}

.popup_content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative;
}

.close_button {
  color: red;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 25px;
  background: none;
  border: none;
  cursor: pointer;
}

.card {
  width: 300px;
  padding: 20px;
  border-radius: 10px;
  background: linear-gradient(135deg, #007bff, #00bfff);
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card_header {
  text-align: center;
}

.card_title {
  margin: 0;
  font-size: 24px;
}

.card_body {
  margin-top: 20px;
}

.card_type {
  font-size: 14px;
  margin-bottom: 10px;
}
.card_number {
  font-size: 18px;
  font-weight: bold;
}
