.popup_container {
  position: fixed;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  max-height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

/* .popup_content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
} */

.popup_content {
  width: 100%;
  max-width: 100vw;
  height: 100%;
  max-height: 100vh;
  overflow: auto;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding-bottom: 80px;
}

.filter_header {
  position: fixed;
  width: 100%;
  /* background-color: black; */
  color: white; /* Set text color to white for better visibility */
  text-align: center;
  font-weight: bold;
  top: 0;
  right: 0;
  padding: 10px;
  z-index: 9999;
}

.close_icon {
  color: red;
  position: absolute;
  font-size: 20px;
  top: 18px;
  left: 10px;
  cursor: pointer;
}

.filter_text {
  color: black;
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
}

/* body.popup-open {
  overflow: hidden;
} */

@media (max-width: 1380px) {
  .apartment_list {
    margin-left: 10px;
    margin-right: 0px;
  }
}

@media (max-width: 1024px) {
  .apartment_list {
    margin-left: 40px;
    margin-right: 0px;
  }
}

@media (max-width: 768px) {
  .apartment_list {
    margin-left: 10px;
    margin-right: 0px;
  }
}

@media (min-width: 540px) and (max-width: 540px) {
  .apartment_list {
    margin-left: 50px;
    margin-right: 30px;
  }
}

@media (max-width: 430px) {
  .apartment_list {
    margin-left: 30px;
    margin-right: 30px;
  }
}

@media (min-width: 540px) and (max-width: 540px) {
  .apartment_pag {
    width: 350px;
    margin-left: 40px;
  }
}

@media (min-width: 280px) and (max-width: 280px) {
  .apartment_pag {
    width: 250px;
  }
}
/* @media (min-width: 280px) and (max-width: 280px) {
  .no_apartment {
    width: 150px;
  }
} */

@media (max-width: 540px) {
  .no_apartment {
    margin-left: -50px;
  }
}

@media (max-width: 430px) {
  .no_apartment {
    margin-left: -30px;
  }
}

@media (max-width: 414px) {
  .no_apartment {
    margin-left: -50px;
  }
}

@media (max-width: 375px) {
  .no_apartment {
    margin-left: -45px;
  }
}

@media (max-width: 360px) {
  .no_apartment {
    margin-left: -40px;
  }
}

@media (max-width: 280px) {
  .no_apartment {
    margin-left: -50px;
  }
}
