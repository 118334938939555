.leftNav {
  margin-left: 550px;
}

.noScrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.noScrollbar {
  -ms-overflow-style: none; /* Hide scrollbar for Internet Explorer (Edge) */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.popup_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  display: flex;
  justify-content: center; /* center horizontally */
  align-items: center; /* center vertically */
}

.popup_content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative;
}

.close_button {
  color: red;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 25px;
  background: none;
  border: none;
  cursor: pointer;
}

.custom_check {
  font-size: 12px;
  width: 60px;
  font-weight: bold;
}

@media (max-width: 285px) {
  .custom_check {
    font-size: 12px;
    width: 20px;
    font-weight: bold;
  }
}
.custom_date {
  margin-top: 2px;
  font-size: 10px;
  font-weight: bold;
}

/* BEST HOST */
/* .host_slide {
  width: 0px;
  margin: auto;
} */

/* FEATURE APARTMENT */
.feature_slide {
  margin: auto;
  width: 100%;
  overflow: hidden;
}

.host_btn {
  margin-top: 50px;
}

@media (min-width: 1024px) and (max-width: 1024px) {
  .host_btn {
    margin-top: 30px;
  }
}
