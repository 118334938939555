.dropdown_container {
  position: relative;
  display: inline-block;
}

.box {
  /* Style for the box */
}

.dropdown {
  position: absolute;
  top: 100%;
  right: -50%;
  background-color: #f9f9f9;
  min-width: 280px;
  text-align: left;
  border: 1px solid #ddd;
  z-index: 1;
  padding: 30px;
}
.language_dropdown_container {
  position: relative;
  display: inline-block;
}
.language_dropdown {
  position: absolute;
  top: 100%;
  right: -20%;
  background-color: #f9f9f9;
  color: "black";
  min-width: 100px;

  border: 1px solid #ddd;
  z-index: 1;
}

/* .dropdown button {
  width: 100%;
  padding: 8px 12px;
  text-align: left;
  background-color: #f9f9f9;
  border: none;
  outline: none;
  cursor: pointer;
} */

.dropdown button:hover {
  background-color: #f1f1f1;
}

.side_dropdown {
  position: relative;
}

.side_dropdown_toggle {
  background: none;
  border: none;
  cursor: pointer;
}

.side_dropdown_content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 99999;
  transition: opacity 0.3s ease-in-out;
}

.side_dropdown_content.show {
  display: block;
  opacity: 1;
}

.hide_scrollbar {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; /* optional, improves touch scrolling on mobile devices */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hide_scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari */
}
