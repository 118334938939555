.landing {
  background: linear-gradient(to bottom, #e6f1fe, white);
}
.apartment_list {
  background: #eeeeee;
}

.custom-calendar-wrapper {
  background-color: lightgray; /* Change to your desired color */
  padding: 10px; /* Optional padding for visual adjustment */
}
