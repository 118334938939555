/* .scrollable_content {
  height: 150px;
} */

.scrollable_content::-webkit-scrollbar {
  display: none;
}

.hide_scrollbar {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; /* optional, improves touch scrolling on mobile devices */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hide_scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari */
}
